import { useEffect } from "react";
import { mobileMenu } from "../../data/menu";

const MobileMenu = () => {
  function navHighlighter() {
    const pageSections = document.querySelectorAll("section[id]");
    let scrollY = window.pageYOffset;
    pageSections.forEach((current) => {
      const sectionHeight = current.offsetHeight;
      const sectionTop =
        current.getBoundingClientRect().top + window.pageYOffset - 50;
      let sectionId = current.getAttribute("id");
      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        document
          .querySelector(".mobile-menu-items a[href*=" + sectionId + "]")
          ?.classList.add("active");
      } else {
        document
          .querySelector(".mobile-menu-items a[href*=" + sectionId + "]")
          ?.classList.remove("active");
      }
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", navHighlighter);

    return () => window.removeEventListener("scroll");
  }, []);

  return (
    <div className="mobile-menu d-md-none">
      <ul className="mobile-menu-items">
        {mobileMenu.map((item) => (
          <li key={item.id}>
            <a href={item.link}>
              <span className="icon home-icon">{item.icon}</span>
              <span className="label">{item.text}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MobileMenu;
