import { createContext } from "react";
import useTheme from "../hooks/useTheme";
export const themeContext = createContext();

const ThemeProvider = ({ children }) => {
  const { theme, toggleTheme } = useTheme();


  return (
    <themeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </themeContext.Provider>
  );
};

export default ThemeProvider;
