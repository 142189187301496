import { useContext } from "react";
import { themeContext } from "../../context/themeContext";
import { bannerOneData } from "../../data/banner";
import { social } from "../../data/social-media";

const HeroBanner2 = () => {
  const { theme } = useContext(themeContext);

  return (
    <section className="hero-banner developer" id="home">
      <div className="container d-flex align-items-center justify-content-center h-100 text-center">
        {bannerOneData.map((data, index) => {
          return (
            <div key={index} className="banner-space">
              <div className="hero-top">
                <span className="hero-avatar d-inline-block">
                  {/* Icons Hands  */}
                  <img src={data.profileImage} alt="user" />
                </span>
                <div className="hero-tooltip left">
                  <span className="tooltip-text">{data.toolTipText}</span>
                  <span className="tooltip-icon">
                    <img
                      src={data.arrow[`${theme}`].leftArrowIcon}
                      alt="icon"
                    />
                  </span>
                </div>
                <h3 className="mb-0">
                  {/* Hands Icons  */}
                  <span className="hand-icon">
                    <img src={data.handIcon} alt="icon" />
                  </span>
                  <span className="align-middle hero-sub-title">
                    <span> {data.subTitle}</span>
                    <span className="text-shape">{data.name}</span>
                  </span>
                </h3>
                <div className="hero-tooltip right d-none d-sm-block">
                  <span className="tooltip-icon">
                    <img
                      src={data.arrow[`${theme}`].rightArrowIcon}
                      alt="icon"
                    />
                  </span>
                  <span className="tooltip-text">{data.toolTipText2}</span>
                </div>
              </div>
              <div className="hero-title">
                <h1 className="mb-0">
                  {data.title}
                  {/* Icons Flower */}
                  <span className="fan-icons d-none d-sm-inline-block">
                    <img src={data.fanIcon} alt="icon" />
                  </span>
                </h1>
              </div>
              {/* Banner Content */}
              <div className="hero-pera">
                <p>{data.content}</p>
              </div>
              <div className="hero-button d-inline-flex gap-3">
                <a
                  href="my-cv.pdf"
                  download={data.cv}
                  target="_blank"
                  className="download-btn"
                >
                  Download CV
                </a>
                <a
                  href="#contact"
                  className="lets-talk-btn d-flex align-items-center"
                >
                  Let's Talk
                  <svg
                    width="24"
                    height="9"
                    viewBox="0 0 24 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25001 3.99973C0.97387 3.99972 0.750007 4.22357 0.75 4.49972C0.749993 4.77586 0.973846 4.99972 1.24999 4.99973L1.25001 3.99973ZM23.1035 4.85381C23.2988 4.65855 23.2988 4.34197 23.1036 4.1467L19.9217 0.964642C19.7264 0.769375 19.4098 0.769367 19.2146 0.964625C19.0193 1.15988 19.0193 1.47646 19.2145 1.67173L22.0429 4.50023L19.2144 7.32859C19.0191 7.52384 19.0191 7.84043 19.2144 8.03569C19.4096 8.23096 19.7262 8.23097 19.9215 8.03571L23.1035 4.85381ZM1.24999 4.99973L22.75 5.00024L22.75 4.00024L1.25001 3.99973L1.24999 4.99973Z"
                      fill="black"
                    />
                  </svg>
                </a>
              </div>
            </div>
          );
        })}
      </div>
      <ul className="social-media d-lg-block d-none">
        {social.map(({ icon, href }, index) => {
          return (
            <li key={index}>
              <a href={href}>
                <i className={icon} />
              </a>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default HeroBanner2;
