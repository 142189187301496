import useTheme from "../hooks/useTheme";

const Preloader = () => {
  const { theme } = useTheme();
  return (
    <div id="preloader" className={`preloader ${theme}`}>
      <div className="animation-preloader">
        <div className="spinner"></div>
        <p className="text-center">Loading</p>
      </div>
      <div className="loader">
        <div className="row">
          <div className="col-3 loader-section section-left">
            <div className="bg"></div>
          </div>
          <div className="col-3 loader-section section-left">
            <div className="bg"></div>
          </div>
          <div className="col-3 loader-section section-right">
            <div className="bg"></div>
          </div>
          <div className="col-3 loader-section section-right">
            <div className="bg"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
