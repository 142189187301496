import { AnimatePresence, motion } from "framer-motion";
import { wrap } from "popmotion";
import React, { useState } from "react";
import { TestimonialList } from "../../data/testimonial";
import prev from "../../img/icons/arrow-left-blue.svg";
import next from "../../img/icons/arrow-right-blue.svg";
import Star from "../../img/icons/star.svg";
import whiteStar from "../../img/icons/white-star.svg";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const SwapSlide = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, TestimonialList.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <div className="testimonial-slider d-flex d-md-none">
      <div
        id="carouselExampleCaptions"
        className="carousel slide slider-items position-relative"
        data-bs-ride="carousel"
      >
        <div className="slider-list carousel-inner">
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              className="slider-item position-absolute"
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
            >
              <div className="slider-img text-center">
                <img
                  src={TestimonialList[imageIndex].image}
                  alt="slider"
                />
              </div>
              <h4 className="slider-name">
                <span>{TestimonialList[imageIndex].name}</span>
              </h4>
              <div className="testimonial-stars">
                {Array(5)
                  .fill("")
                  .map((_, i) => (
                    <React.Fragment key={i}>
                      {TestimonialList[imageIndex].rating > i ? (
                        <img src={Star} alt="star" />
                      ) : (
                        <img src={whiteStar} alt="star" />
                      )}
                    </React.Fragment>
                  ))}
              </div>
              <div className="desc-wrapper">
                <p className="slider-desc">
                  {TestimonialList[imageIndex].description}
                </p>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="testimonials-control">
          <button
            onClick={() => paginate(-1)}
            className="carousel-control-prev prev"
            type="button"
          >
            <img src={prev} alt="prev icon" />
          </button>
          <button
            className="carousel-control-next next"
            onClick={() => paginate(1)}
            type="button"
          >
            <img src={next} alt="next icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SwapSlide;
