import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import Count from "./counter";

import { projectData } from "../../data/project-list";

const ProjectList = () => {
  const projectList = useRef(null);
  const isInView = useInView(projectList, { once: true });
  return (
    <section id="project-list-section" className="project-list-section">
      <div className="container">
        {/* Project counter */}
        <div ref={projectList} className="project-list">
          <div className="row">
            {isInView && (
              <>
                {/* Single counter */}
                {projectData.map((data, index) => {
                  return (
                    <div className="col-6 col-lg-3 pe-0" key={index}>
                      <div className="box mb-4 mb-lg-0">
                        <h2>
                          <motion.span>
                            <Count
                              className="counter"
                              min={0}
                              max={data.project}
                              speed={3000}
                            />
                          </motion.span>
                          <sup>+</sup>
                        </h2>
                        <p className="project-des">{data.description}</p>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectList;
