export const timelines = [
  {
    id: 1,
    time: "Sep. 2021- Jan. 2022",
    position: "Sr. Software Engineer",
    company: "AT&T",
    location: "USA ",
    content: `Digital Locker: Design and develop custom application using Cocoa and Objective-C. Integrate with server side components.`,
  },
  {
    id: 2,
    time: "Sep. 2020- Jan. 2021",
    position: "Sr. Software Engineer",
    company: "AOL, Ine",
    location: "Melbourne, Australia",
    content: `This project used Cocoa/MacOSX and ObjC. Maintain and enhance a version of the Winamp music player for the Cocoa platform. Working on this application requires a great deal of experience with Cocoa and Objective-C. Make certain that the application can always sync properly with the smartphones it supports: Android mainly.Also assisted on the android side with Winamp for Android. Worked on android studio to build and install Winamp and tested on various phones and other android based devices. Utilized ALSA and TinyALSA.`,
  },
  {
    id: 3,
    time: "Sep. 2018- Jan. 2020",
    position: "Sr. Software Engineer",
    company: "TestPlant, LLC",
    location: "Francisco, California",
    content: ` Port Eggplant application from Mac OS X to Linux and Windows using
     GNUstep. Identify gaps in functionality and implement those in the GNUstep framework.`,
  },
  {
    id: 4,
    time: "Sep. 2016- Jan. 2018",
    position: "Sr. Software Engineer",
    company: "Mosaic Learning, Ine",
    location: "Washington, United States",
    content: ` Created and maintained an iPad application to provide an eResder for publications from iTi Ainternational Training
    Institute for SheeLmetal workers). This reuder was written using Objective.
    C on iDS and read and stored encrypted documents. For the user. It used SOAP to communicate with the external webservice which provided the publications. The cReader made use of many of the ndvanced fentures of iOS including VGA output and page turn animations
    eLearning Project: Worked on an application to help workers learn to use
    equipent in the field using a Unity game`,
  },
  {
    id: 5,
    time: "Sep. 2014- Jan. 2016",
    position: "Software Engineer",
    company: "GTP. Inc",
    location: "Melean, VA, USA",
    content: `Customink Worked on a system for building T-Shirts and other personalized iters onfine, Used RoR extensively to ereate a system which would allow the compans representatives to choose the printer company to outsorree production of the tems to.`,
  },
];
