import { useEffect, useRef } from "react";

 // for bottom to top button
const BottomUpButton = () => {
  const ref = useRef();

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (this.window.scrollY > 300) {
        ref.current.classList.add("show");
      } else {
        ref.current.classList.remove("show");
      }
    });

    return () => window.removeEventListener("scroll");
  }, []);

  return (
    <a ref={ref} className="bottom-top-button d-none d-md-block" href="#home">
      <span></span>
    </a>
  );
};

export default BottomUpButton;
