import { motion } from "framer-motion";
import { useState } from "react";
import { timelines } from "../data/experience/experience";
import locationIcon from "../img/icons/location.svg";

const timelineVariants = {
  offscreen: {
    x: 300,
    opacity: 0,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      x: { duration: 0.6 },
      type: "tween",
      ease: "easeInOut",
    },
  },
};

const listVariants = {
  offscreen: {
    x: -300,
    opacity: 0,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      mass: 1,
      bounce: 1,
      damping: 25,
    },
  },
};

const Experience = () => {
  const [key, setKey] = useState("timeline");
  const [activeId, setActiveId] = useState(1);

  return (
    <section id="experience" className="experience  pt-0">
      <div className="container">
        <div className="experience-top d-flex justify-content-between align-items-center gy-md-0 flex-wrap flex-md-nowrap">
          <h2 className="section-heading title-signature">Experience</h2>
          {/* Experience tab  */}
          <ul className="nav nav-tabs mt-0" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              {/* Tab Button  */}
              <button
                onClick={() => setKey("timeline")}
                className={`nav-link ${key === "timeline" ? "active" : ""}`}
                id="timeline-tab"
                data-bs-toggle="tab"
                data-bs-target="#timeline"
                type="button"
                role="tab"
                aria-controls="timeline"
                aria-selected="true"
              >
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_23_5516)">
                <path d="M4 2.53125C4.19891 2.53125 4.38968 2.62014 4.53033 2.77838C4.67098 2.93661 4.75 3.15122 4.75 3.375C4.75 3.59878 4.67098 3.81339 4.53033 3.97162C4.38968 4.12986 4.19891 4.21875 4 4.21875C3.80109 4.21875 3.61032 4.12986 3.46967 3.97162C3.32902 3.81339 3.25 3.59878 3.25 3.375C3.25 3.15122 3.32902 2.93661 3.46967 2.77838C3.61032 2.62014 3.80109 2.53125 4 2.53125ZM5 5.95195C5.88437 5.51953 6.5 4.52812 6.5 3.375C6.5 1.82109 5.38125 0.5625 4 0.5625C2.61875 0.5625 1.5 1.82109 1.5 3.375C1.5 4.52812 2.11563 5.51953 3 5.95195V7.875H1C0.446875 7.875 0 8.37773 0 9C0 9.62227 0.446875 10.125 1 10.125H9V12.048C8.11563 12.4805 7.5 13.4719 7.5 14.625C7.5 16.1789 8.61875 17.4375 10 17.4375C11.3813 17.4375 12.5 16.1789 12.5 14.625C12.5 13.4719 11.8844 12.4805 11 12.048V10.125H19C19.5531 10.125 20 9.62227 20 9C20 8.37773 19.5531 7.875 19 7.875H17V5.95195C17.8844 5.51953 18.5 4.52812 18.5 3.375C18.5 1.82109 17.3813 0.5625 16 0.5625C14.6187 0.5625 13.5 1.82109 13.5 3.375C13.5 4.52812 14.1156 5.51953 15 5.95195V7.875H5V5.95195ZM15.25 3.375C15.25 3.15122 15.329 2.93661 15.4697 2.77838C15.6103 2.62014 15.8011 2.53125 16 2.53125C16.1989 2.53125 16.3897 2.62014 16.5303 2.77838C16.671 2.93661 16.75 3.15122 16.75 3.375C16.75 3.59878 16.671 3.81339 16.5303 3.97162C16.3897 4.12986 16.1989 4.21875 16 4.21875C15.8011 4.21875 15.6103 4.12986 15.4697 3.97162C15.329 3.81339 15.25 3.59878 15.25 3.375ZM10 13.7812C10.1989 13.7812 10.3897 13.8701 10.5303 14.0284C10.671 14.1866 10.75 14.4012 10.75 14.625C10.75 14.8488 10.671 15.0634 10.5303 15.2216C10.3897 15.3799 10.1989 15.4688 10 15.4688C9.80109 15.4688 9.61032 15.3799 9.46967 15.2216C9.32902 15.0634 9.25 14.8488 9.25 14.625C9.25 14.4012 9.32902 14.1866 9.46967 14.0284C9.61032 13.8701 9.80109 13.7812 10 13.7812Z" fill="black"/>
                </g>
                <defs>
                <clipPath id="clip0_23_5516">
                <rect width="20" height="18" fill="black"/>
                </clipPath>
                </defs>
                </svg>
                Timeline
              </button>
            </li>
            <li className="nav-item" role="presentation">
              {/* Tab button */}
              <button
                onClick={() => setKey("list")}
                className={`nav-link ${key === "list" ? "active" : ""}`}
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#list"
                type="button"
                role="tab"
                aria-controls="profile-tab"
                aria-selected="false"
              >
                <svg
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7272 0H9.45442C9.03214 0 8.64202 0.225258 8.43098 0.591068C8.21995 0.956677 8.21995 1.40721 8.43098 1.77278C8.64202 2.13835 9.03218 2.36364 9.45442 2.36364H17.7272C18.1494 2.36364 18.5396 2.13838 18.7506 1.77278C18.9616 1.40718 18.9616 0.95664 18.7506 0.591068C18.5396 0.225252 18.1494 0 17.7272 0Z"
                    fill="black"
                  />
                  <path
                    d="M17.7272 5.31812H9.45442C9.03214 5.31812 8.64202 5.54337 8.43098 5.90897C8.21995 6.27479 8.21995 6.72532 8.43098 7.0909C8.64202 7.45651 9.03218 7.68175 9.45442 7.68175H17.7272C18.1494 7.68175 18.5396 7.4565 18.7506 7.0909C18.9616 6.72529 18.9616 6.27476 18.7506 5.90897C18.5396 5.54336 18.1494 5.31812 17.7272 5.31812Z"
                    fill="black"
                  />
                  <path
                    d="M17.7272 10.6362H9.45442C9.03214 10.6362 8.64202 10.8615 8.43098 11.2271C8.21995 11.5927 8.21995 12.0432 8.43098 12.409C8.64202 12.7746 9.03218 12.9999 9.45442 12.9999H17.7272C18.1494 12.9999 18.5396 12.7746 18.7506 12.409C18.9616 12.0432 18.9616 11.5927 18.7506 11.2271C18.5396 10.8615 18.1494 10.6362 17.7272 10.6362Z"
                    fill="black"
                  />
                  <path
                    d="M1.18171 12.9999H4.13626C4.55855 12.9999 4.94866 12.7746 5.1597 12.409C5.37094 12.0432 5.37094 11.5927 5.1597 11.2271C4.94866 10.8615 4.5585 10.6362 4.13626 10.6362H1.18171C0.75943 10.6362 0.369319 10.8615 0.158279 11.2271C-0.0527598 11.5927 -0.0527598 12.0432 0.158279 12.409C0.369319 12.7746 0.759477 12.9999 1.18171 12.9999Z"
                    fill="black"
                  />
                  <path
                    d="M1.18171 2.36364H4.13626C4.55855 2.36364 4.94866 2.13838 5.1597 1.77278C5.37094 1.40717 5.37094 0.95664 5.1597 0.591068C4.94866 0.225252 4.5585 0 4.13626 0H1.18171C0.75943 0 0.369319 0.225258 0.158279 0.591068C-0.0527598 0.956677 -0.0527598 1.40721 0.158279 1.77278C0.369319 2.13835 0.759477 2.36364 1.18171 2.36364Z"
                    fill="black"
                  />
                  <path
                    d="M1.18171 7.68175H4.13626C4.55855 7.68175 4.94866 7.4565 5.1597 7.0909C5.37094 6.72529 5.37094 6.27476 5.1597 5.90897C4.94866 5.54336 4.5585 5.31812 4.13626 5.31812H1.18171C0.75943 5.31812 0.369319 5.54337 0.158279 5.90897C-0.0527598 6.27479 -0.0527598 6.72532 0.158279 7.0909C0.369319 7.45651 0.759477 7.68175 1.18171 7.68175Z"
                    fill="black"
                  />
                </svg>
                List
              </button>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="myTabContent">
          {/* Timeline tab content  */}
          <div
            className={`tab-pane fade show ${
              key === "timeline" ? "active" : ""
            }`}
            id="timeline"
            role="tabpanel"
            aria-labelledby="timeline-tab"
          >
            {/* Timeline */}
            <div className="experience__timeline">
              <ul>
                {timelines.map(
                  ({ id, time, position, company, location, content }) => (
                    <motion.li
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{ once: false, amount: 0.8 }}
                      onClick={() => setActiveId(id)}
                      className={`timeline-item ${
                        activeId === id ? "active" : ""
                      }`}
                      key={id}
                    >
                      <div className="left">
                        <p className="text-right">{time}</p>
                        <div className="circle" onClick={() => setActiveId(id)}>
                          <span></span>
                        </div>
                      </div>
                      <motion.div variants={timelineVariants} className="right">
                        <h2>{position}</h2>
                        <h3>{company}</h3>
                        <div className="d-flex gap-2 location">
                          <img src={locationIcon} alt="location" />
                          <span>{location}</span>
                        </div>
                        <p>{content}</p>
                      </motion.div>
                    </motion.li>
                  )
                )}
              </ul>
            </div>
          </div>
          {/* List tab content */}
          <div
            className={`tab-pane fade show ${key === "list" ? "active" : ""}`}
            id="list"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="experience_list_container d-flex flex-column gap-3">
              {timelines
                .slice(0, -1)
                .map(
                  ({ time, position, company, location, content }, index) => (
                    <motion.div
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{ once: false, amount: 0.5 }}
                      key={index}
                    >
                      <motion.div
                        className="experience__item"
                        variants={listVariants}
                      >
                        <h2>
                          <span>{time}</span>
                        </h2>
                        <h3>{position}</h3>
                        <h4>{company}</h4>
                        <div className="d-flex align-items-center gap-2 mb-2">
                          <img src={locationIcon} alt="location" />
                          <span className="address">{location}</span>
                        </div>
                        <p>{content}</p>
                      </motion.div>
                    </motion.div>
                  )
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
