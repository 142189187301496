import { motion } from "framer-motion";
import { cardsLayout } from "../../data/feature-project/card-list";
const FeaturedModal = ({ id, setId }) => {
  const { img, title, subTitle, description } = cardsLayout.find(
    (card) => card.id === id
  );
  return (
    <motion.div layoutId={`container-${id}`} className="overlay">
      <div className="container">
        <div className="feature-project-modal mt-5 absolute">
          <div className="row">
            <div className="clearfix">
              <div className="float-md-start col-sm-12 col-md-5 me-md-3">
                <motion.div layoutId={`img-${id}`} className="project-item-img">
                  <img
                    src={img}
                    className="w-100 rounded"
                    alt="Mobile App Design"
                  />
                </motion.div>
              </div>
              <motion.div className="col" layoutId={`details-${id}`}>
                <div className="details p-0 ">
                  <h4 className="mt-3 mt-md-0">
                    <span>{title}</span>
                  </h4>
                  <h2>{subTitle}</h2>
                  <p dangerouslySetInnerHTML={{ __html: description }}></p>
                </div>
              </motion.div>
            </div>
          </div>
          <button
            onClick={() => setId(null)}
            type="button"
            className="btn-close modal-close-btn"
          >
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 0C9.40275 0 0 9.40275 0 21C0 32.5972 9.40275 42 21 42C32.5972 42 42 32.5972 42 21C42 9.40275 32.5972 0 21 0ZM23.4745 21C23.4745 21 28.9678 26.4932 29.2373 26.7627C29.9215 27.447 29.9215 28.5548 29.2373 29.2373C28.553 29.9215 27.4452 29.9215 26.7627 29.2373C26.4932 28.9695 21 23.4745 21 23.4745C21 23.4745 15.5067 28.9678 15.2372 29.2373C14.553 29.9215 13.4453 29.9215 12.7628 29.2373C12.0785 28.553 12.0785 27.4452 12.7628 26.7627C13.0305 26.4932 18.5255 21 18.5255 21C18.5255 21 13.0323 15.5067 12.7628 15.2372C12.0785 14.553 12.0785 13.4453 12.7628 12.7628C13.447 12.0785 14.5547 12.0785 15.2372 12.7628C15.5067 13.0305 21 18.5255 21 18.5255C21 18.5255 26.4932 13.0323 26.7627 12.7628C27.447 12.0785 28.5548 12.0785 29.2373 12.7628C29.9215 13.447 29.9215 14.5547 29.2373 15.2372C28.9695 15.5067 23.4745 21 23.4745 21Z"
                fill="#05172B"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </motion.div>
  );
};
export default FeaturedModal;
