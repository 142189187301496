import { useEffect, useRef, useState } from "react";
import Nav from "./Nav";

const Header = ({ setShow }) => {
  const ref = useRef(null);
  const [element, setElement] = useState();

  useEffect(() => {
    setElement(ref.current.firstElementChild);
    window.addEventListener("scroll", function () {
      if (this.window.scrollY > 300) {
        ref.current.classList.add("sticky");
      } else {
        ref.current.classList.remove("sticky");
      }
    });
  }, [element]);

  return (
    <header ref={ref}>
      {/* Navbar  */}
      <Nav />
      {/* Optional Menu open */}
      <button onClick={() => setShow(true)} className="optional-menu-open">
        <svg
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="18.1999"
            height="2.79999"
            rx="1.4"
            transform="matrix(-1 0 0 1 18.2002 0)"
            fill="url(#paint0_linear_1254_5218)"
          ></rect>
          <rect
            width="12.6"
            height="2.79999"
            rx="1.4"
            transform="matrix(-1 0 0 1 18.2002 5.6001)"
            fill="url(#paint1_linear_1254_5218)"
          ></rect>
          <rect
            width="6.29998"
            height="2.79999"
            rx="1.4"
            transform="matrix(-1 0 0 1 18.2002 11.2)"
            fill="url(#paint2_linear_1254_5218)"
          ></rect>
          <defs>
            <linearGradient
              id="paint0_linear_1254_5218"
              x1="4.62961"
              y1="0.441275"
              x2="6.59616"
              y2="7.81903"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#F7971E"></stop>
              <stop offset="1" stopColor="#FFD200"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_1254_5218"
              x1="3.20511"
              y1="0.441275"
              x2="5.85473"
              y2="7.32306"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#F7971E"></stop>
              <stop offset="1" stopColor="#FFD200"></stop>
            </linearGradient>
            <linearGradient
              id="paint2_linear_1254_5218"
              x1="1.60256"
              y1="0.441275"
              x2="5.42236"
              y2="5.40182"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#F7971E"></stop>
              <stop offset="1" stopColor="#FFD200"></stop>
            </linearGradient>
          </defs>
        </svg>
      </button>
    </header>
  );
};

export default Header;
