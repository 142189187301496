import { NavLink } from "react-router-dom";

const OptionalMobileItem = ({ item, setShow }) => {
  return (
    <li key={item.id}>
      <NavLink
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setShow(false);
        }}
        end
        to={item.link}
        className={({ isActive }) =>
          isActive
            ? `active dropdown-btn ${item.id === 1 ? "border-top" : ""}`
            : `dropdown-btn ${item.id === 1 ? "border-top" : ""}`
        }
      >
        <div className="label">{item.menu}</div>
      </NavLink>
    </li>
  );
};

export default OptionalMobileItem;
