import { motion } from "framer-motion";
import { validClients } from "../data/validclient";

const zoomIn = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      times: [0, 0.1, 0.9, 1],
      duration: 2,
    },
  },
};

const zoomOut = {
  hidden: { opacity: 0, scale: 1.4 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "tween",
      duration: 1.5,
      ease: "easeInOut",
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const ValidClient = () => {
  return (
    <section className="client-section ">
      <div className="container">
        <div className="d-flex">
          <h2 className="section-heading">
            <span className="title-signature">My Valid Clients</span>
            <sub>•</sub>
          </h2>
        </div>
        {/* Client's company logo */}
        <ul>
          {validClients.map(({ src, link }, index) => {
            return (index + 1) % 2 !== 0 ? (
              <motion.li
                variants={zoomOut}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false }}
                key={index}
              >
                <motion.a
                  className="overflow-hidden"
                  variants={item}
                  href={link}
                >
                  <img src={src} alt="client" />
                </motion.a>
              </motion.li>
            ) : (
              <motion.li
                variants={zoomIn}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false }}
                key={index}
                style={{ transformOrigin: "center" }}
              >
                <motion.a href={link} variants={item}>
                  <img src={src} alt="client" />
                </motion.a>
              </motion.li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default ValidClient;
