import { useContext } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { themeContext } from "../context/themeContext";
import { navs } from "../data/mobileItem";
import OptionalMobileItem from "./optiona-mobile-items";

const OptionalMobileMenu = ({ show, setShow }) => {
  const { theme, toggleTheme } = useContext(themeContext);

  return (
    <div className={`optional-mobile-menu ${show ? "show" : ""}`}>
      <button onClick={() => setShow(false)} className="optional-menu-close">
        <i className="fas fa-times"></i>
      </button>
      <button onClick={() => toggleTheme()} className={`theme ${theme}`}>
        <DarkModeSwitch
          checked={theme !== "dark"}
          onChange={toggleTheme}
          size={120}
        />
      </button>
      <ul className="optional-menu-items">
        {navs.map((item) => (
          <OptionalMobileItem key={item.id} item={item} setShow={setShow} />
        ))}
      </ul>
    </div>
  );
};

export default OptionalMobileMenu;
