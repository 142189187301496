export const projectData = [
    {
        id:1,
        project: "450",
        description: "Project Complete"
    },
    {
        id:2,
        project: "400",
        description: "Happy Clients"
    },
    {
        id:3,
        project: "50",
        description: "Award Won"
    },
    {
        id:4,
        project: "200",
        description: "Global Clients"
    }
]