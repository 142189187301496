import fanIcon from "../img/icons/flower-icon.svg";
import handIcon from "../img/icons/hand-icon.svg";
import sunIcon from "../img/icons/sun.svg";
import darkLeftArrowIcon from "../img/icons/tooltip-arrow-left-dark.svg";
import leftArrowIcon from "../img/icons/tooltip-arrow-left.svg";
import darkRightArrowIcon from "../img/icons/tooltip-arrow-right-dark.svg";
import rightArrowIcon from "../img/icons/tooltip-arrow-right.svg";

import profileIcon from "../img/hero-banner/profile1.png";
import profileIcon3 from "../img/hero-banner/profile2.png";
import darkProfileIcon2 from "../img/hero-banner/profile3-dark.png";
import lightProfileIcon2 from "../img/hero-banner/profile3.png";

export const bannerOneData = [
  {
    toolTipText: "8 Years Experience",
    toolTipText2: "IOS Developer",
    handIcon: handIcon,
    arrow: {
      light: {
        leftArrowIcon,
        rightArrowIcon,
      },
      dark: {
        leftArrowIcon: darkLeftArrowIcon,
        rightArrowIcon: darkRightArrowIcon,
      },
    },
    subTitle: "Hi, I am ",
    name: "Jack Pacino",
    title: "Full Stack Developer",
    content: `I'm a senior software engineer who specializes in creating massively scalable software systems. Expert in
          creating frameworks, writing code, and working collaboratively to manage and execute the SLDC process
          efficiently. Hire me to help you govern your IT company even more effectively.`,
    fanIcon: fanIcon,
    sunIcon: sunIcon,
    profileImage: profileIcon,
    cv: "my-cv.pdf",
  },
];

export const bannerTwoData = [
  {
    handIcon: handIcon,
    subTitle: "Hi, I am Jack Pacino",
    title: "Full Stack Developer",
    content: `Writing and reading are my hobbies in science I can read and write, and I've been
              professionally writing since 2010. Can help your business by writing copy, SEO content, and
              documenting technology or business in a clear, simple, and domain-specific manner.`,
    fanIcon: fanIcon,
    sunIcon: sunIcon,
    profileImage: {
      light: lightProfileIcon2,
      dark: darkProfileIcon2,
    },
    cv: "my-cv.pdf",
  },
];

export const bannerThreeData = [
  {
    handIcon: handIcon,
    subTitle: "Hi, I am Jack Pacino",
    title: "Full Stack Developer",
    content: `Hello! I'm a creative human being and UI/UX designer who focuses on developing aesthetically pleasing
              and functional products that are user-centric. Hire me to turn your concept into a working product.`,
    fanIcon: fanIcon,
    sunIcon: sunIcon,
    profileImage: profileIcon3,
    cv: "my-cv.pdf",
  },
];
