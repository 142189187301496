import img1 from "../img/testimonial/slider-1.png";
import img2 from "../img/testimonial/slider-2.png";
import img3 from "../img/testimonial/slider-3.png";

export const TestimonialList = [
  {
    id: 1,
    image: img1,
    name: "Joseph",
      rating: 4,
    description:
      "I absolutely recommend my good friend for the software engineering position. They have experience with Java Spring Boot, Node JS, and Python. My friend also is great at collaborating with a team, and always willing to share their expert knowledge with those who may be less experienced. They have a real passion for software engineering, and it shows in the high quality of their work. I know that they would be an excellent addition to any software engineering team.",
  },
  {
    id: 2,
    image: img2,
    name: "Jhone Walker Hussy",
      rating: 5,
    description:
      "If you're looking for a talented software engineer, Java Spring Boot is the way to go. My friend is an expert in Java Spring Boot and has used it to create some amazing software applications. In addition, he is also proficient in Node JS and Python. As a result, he is able to create highly efficient and reliable software systems. I would highly recommend him for any software engineering position.",
  },
  {
    id: 3,
    image: img3,
    name: "Sebastian",
      rating: 2,
    description:
      "If you're looking for a web developer who is well-versed in both back-end and front-end technologies, then you need to meet my friend Sarah. Sarah has worked as a software engineer for the past five years, and in that time she has become an expert in Java Spring Boot, Node JS, and Python. She has also developed a strong eye for detail and a knack for creating elegant and user-friendly interfaces. In short, Sarah is the perfect person to help you turn your web development project into a reality. So if you're looking for someone who can code, design, and execute, thenSarah is your woman. Contact her today and see for yourself! ",
  },
];
