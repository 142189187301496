import {AnimatePresence} from "framer-motion";
import React, {useEffect, useState, useRef} from "react";
import Slide from "./slide";
import SwapSlide from "./swapSlider";

// Data
import {TestimonialList} from "../../data/testimonial";
// Svg Images
import prev from "../../img/icons/arrow-left-blue.svg";
import next from "../../img/icons/arrow-right-blue.svg";


const Index = () => {
    const interval = useRef();
    const [activeSlide, setSlide] = useState(0);

    useEffect(() => {
        interval.current = setInterval(() => {
            setSlide((activeSlide) =>
                activeSlide === TestimonialList.length - 1 ? 0 : activeSlide + 1
            );
        }, 7000);

        return () => clearInterval(interval);
    }, []);

    return (
        <section id="testimonial" className="testimonial-section">
            <div className="container">
                <h2 className="section-heading">
                    <span className="title-signature">Client Testimonial</span>
                    <sub>•</sub>
                </h2>
                <div className="testimonial-slider d-none d-md-flex">
                    <div
                        id="carouselExampleCaptions"
                        className="carousel slide slider-items"
                        data-bs-ride="carousel"
                    >
                        <div className="slider-list carousel-inner">
                            <AnimatePresence mode="wait">
                                {TestimonialList.map((data, index) => (
                                    <React.Fragment key={index}>
                                        {activeSlide === index && <Slide data={data}/>}
                                    </React.Fragment>
                                ))}
                            </AnimatePresence>
                        </div>
                        <div className="testimonials-control">
                            <button
                                className="carousel-control-prev prev"
                                type="button"
                                onClick={() => {
                                    setSlide((activeSlide) =>
                                        activeSlide === 0
                                            ? TestimonialList.length - 1
                                            : activeSlide - 1
                                    );
                                }}
                            >
                                <img src={prev} alt="prev icon"/>
                            </button>
                            <button
                                onClick={() => {
                                    setSlide((activeSlide) =>
                                        activeSlide === TestimonialList.length - 1
                                            ? 0
                                            : activeSlide + 1
                                    );
                                }}
                                className="carousel-control-next next"
                                type="button"
                            >
                                <img src={next} alt="next icon"/>
                            </button>
                        </div>
                    </div>
                </div>
                <SwapSlide/>
            </div>
        </section>
    );
};
export default Index;
