import {
  AnimatePresence,
  motion,
  useAnimation,
  useInView,
} from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { expertises } from "../../data/expertise";
import { social } from "../../data/social-media";
import withVideoModal from "../../HOC/withVideoModal";
import ScrollBottomIcon from "../../img/icons/scroll-bottom.svg";
import ExpertiseModal from "./expertiseModal";

const cardUp = {
  initial: {
    y: 200,
    opacity: 0,
  },
  animate: {
    y: 0,

    opacity: 1,
    transition: {
      type: "spring",

      bounce: 0.5,
      damping: 8,
      stiffness: 50,
    },
  },
};

const cardDown = {
  initial: {
    y: -200,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",

      bounce: 0.5,
      damping: 8,
      stiffness: 50,
    },
  },
};

const transition = {
  type: "tween",
  ease: [0.32, 0.72, 0, 1],
  duration: 0.5,
};

let dir = "ltr";
let interval = null;
const speed = 5000;

const Expertise = ({ setOpen }) => {
  const [selectedId, setId] = useState(null);
  const [cardWidth, setcardWidth] = useState(0);
  const [isHover, setHover] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { pathname } = useLocation();
  const prev = useRef(null);
  const next = useRef(null);
  const dragRef = useRef(null);
  const cardRef = useRef(null);
  const [width, setWidth] = useState(0);
  const animation = useAnimation();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: false });

  useEffect(() => {
    setWidth(dragRef.current.scrollWidth - dragRef.current.offsetWidth);
    setcardWidth(dragRef.current.scrollWidth / expertises.length);
    prev.current.classList.add("disabled");

    interval = setInterval(() => {
      if (!isHover) {
        const xPos = translateXForElement(dragRef.current);

        if (dir === "ltr") {
          onRightClick();
          dir = xPos === -width ? "rtl" : "ltr";
        } else {
          onLeftClick();
          dir = xPos === 0 ? "ltr" : "rtl";
        }
      }
    }, speed);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardWidth, isHover]);

  const translateXForElement = (element) => {
    const transform = element.style.transform;
    if (!transform || transform?.indexOf("translateX(") < 0) {
      return 0;
    }
    const extractTranslateX = transform.match(/translateX\((-?\d+)/);
    return extractTranslateX?.length === 2
      ? Math.ceil(extractTranslateX[1])
      : 0;
  };

  async function onLeftClick() {
    const xPos = translateXForElement(dragRef.current);
    const newXPosition = xPos + cardWidth;
    await animation.start({
      x: newXPosition < 0 ? newXPosition : 0,
      transition,
    });
    onDragGetSliderPosition();
  }

  async function onRightClick() {
    const xPos = translateXForElement(dragRef.current);
    const newXPosition = xPos - cardWidth;
    await animation.start({
      x: newXPosition >= -width ? newXPosition : -width,
      transition,
    });
    onDragGetSliderPosition();
  }

  function onDragGetSliderPosition() {
    if (translateXForElement(dragRef.current) <= -width + 2) {
      next.current.classList.add("disabled");
    } else {
      next.current.classList.remove("disabled");
    }

    if (translateXForElement(dragRef.current) < 0) {
      prev.current.classList.remove("disabled");
    } else {
      prev.current.classList.add("disabled");
    }
  }

  return (
    <>
      <section id="expertise" className="expertise">
        {pathname === "/" && (
          <div
            className={`d-none videoBtn d-lg-block`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <button
              onClick={() => setOpen(true)}
              type="button"
              className="videoPlayButton"
            >
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29.8614 59.7228C46.3534 59.7228 59.7228 46.3534 59.7228 29.8614C59.7228 13.3694 46.3534 0 29.8614 0C13.3694 0 0 13.3694 0 29.8614C0 46.3534 13.3694 59.7228 29.8614 59.7228Z"
                  fill="white"
                />
                <path
                  d="M39.9428 25.8058C42.6614 27.335 42.6614 31.2492 39.9428 32.7784L27.835 39.589C25.1686 41.0889 21.874 39.162 21.874 36.1027V22.4815C21.874 19.4222 25.1686 17.4953 27.835 18.9952L39.9428 25.8058Z"
                  fill="url(#paint0_linear_1885_9145)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1885_9145"
                    x1="42.3162"
                    y1="22.5865"
                    x2="22.0057"
                    y2="53.8663"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F7971E" />
                    <stop offset="1" stopColor="#FFD200" />
                  </linearGradient>
                </defs>
              </svg>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        )}

        {pathname === "/header2" && (
          <div className={`scroll-to-bottom d-none d-lg-block`}>
            <a href="#expertise">
              <img src={ScrollBottomIcon} alt="icon" />
              <span className="line"></span>
              <span className="dot"></span>
            </a>
          </div>
        )}

        {/*-- Banner socials links -*/}
        {pathname === "/header3" && (
          <ul
            className={`social-media d-lg-flex justify-content-center align-items-center d-none`}
          >
            {social.map(({ icon, href }, index) => {
              return (
                <li key={index}>
                  <a href={href}>
                    <i className={icon} />
                  </a>
                </li>
              );
            })}
          </ul>
        )}

        <div className="container overflow-hidden">
          <div className="expertise-top d-flex justify-content-between align-items-center">
            <h2 className="section-heading title-signature">
              Expertise<sub>.</sub>
            </h2>
            <div id="experienceCarouselNav" className="owl-nav">
              <button
                ref={prev}
                onClick={onLeftClick}
                type="button"
                role="presentation"
                className="owl-prev"
              >
                <i className="fas fa-arrow-left"></i>
              </button>
              <button
                ref={next}
                onClick={onRightClick}
                type="button"
                role="presentation"
                className="owl-next"
              >
                <i className="fas fa-arrow-right"></i>
              </button>
            </div>
          </div>
          {/* Expertise Carousel */}
          <div ref={containerRef}>
            <motion.div
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              ref={dragRef}
              drag="x"
              dragMomentum={false}
              animate={animation}
              onDragEnd={onDragGetSliderPosition}
              dragConstraints={{ left: -width, right: 0 }}
              dragElastic={0.2}
              className="expertise__services-carousel"
            >
              {expertises?.map(({ icon, title, content, id }, index) => {
                return (
                  <motion.div
                    variants={(index + 1) % 2 === 0 ? cardUp : cardDown}
                    animate={isInView ? "animate" : "initial"}
                    key={index}
                    ref={cardRef}
                  >
                    <div className="service__card">
                      <div className="card-icon">{icon}</div>
                      <div className="service__content">
                        <div>
                          <h3>{title}</h3>
                          <p>{content}</p>
                        </div>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleShow();
                            setId(id);
                          }}
                          className="learn__more"
                          type="button"
                        >
                          <span className="btn-text">Learn More</span>
                          <span className="btn-icon">
                            <i className="fas fa-arrow-right"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </motion.div>

            <AnimatePresence mode="wait">
              {show && (
                <ExpertiseModal
                  id={selectedId}
                  show={show}
                  handleClose={handleClose}
                />
              )}
            </AnimatePresence>
          </div>
        </div>
      </section>
    </>
  );
};
export default withVideoModal(Expertise);
