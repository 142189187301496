import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import VideoModal from "../components/videoModal";

const withVideoModal = (Component) => {
  return () => {
    const [isOpen, setOpen] = useState(false);

    return (
      <>
        <AnimatePresence mode="wait">
          {isOpen && <VideoModal setOpen={setOpen} isOpen={isOpen} />}
        </AnimatePresence>

        <Component setOpen={setOpen} />
      </>
    );
  };
};

export default withVideoModal;
