import img1 from "../img/expertise/grapic-design.svg";
import img2 from "../img/expertise/ui-ux.svg";
import img3 from "../img/expertise/design-system.svg";
import img4 from "../img/expertise/prototyping.svg";
import img5 from "../img/expertise/interaction-design.svg";
import img6 from "../img/expertise/mobile-app-aesign.svg";

export const expertises = [
  {
    id: 1,
    icon: (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M31.1668 11.3335V22.936C31.1668 28.0927 28.0927 31.1668 22.936 31.1668H11.0643C5.90766 31.1668 2.8335 28.0927 2.8335 22.936V11.3477L31.1668 11.3335Z"
          fill="url(#paint0_linear_1475_4605)"
        />
        <path
          d="M12.7502 25.1458C12.5944 25.1458 12.4244 25.1033 12.2827 25.0324C11.1919 24.4799 10.2569 23.6583 9.57686 22.6383C9.01019 21.7883 9.01019 20.6974 9.57686 19.8474C10.2569 18.8274 11.1919 18.0058 12.2827 17.4674C12.8069 17.1983 13.4444 17.4249 13.7135 17.9491C13.9827 18.4733 13.7702 19.1108 13.2319 19.3799C12.4669 19.7624 11.8152 20.3433 11.3477 21.0516C11.2627 21.1791 11.2627 21.3491 11.3477 21.4908C11.8152 22.1991 12.4669 22.7799 13.2319 23.1624C13.756 23.4316 13.9685 24.0691 13.7135 24.5933C13.5152 24.9333 13.1327 25.1458 12.7502 25.1458Z"
          fill="url(#paint1_linear_1475_4605)"
        />
        <path
          d="M21.5476 25.1459C21.1509 25.1459 20.7826 24.9334 20.5984 24.5651C20.3292 24.0409 20.5417 23.4034 21.0801 23.1343C21.8451 22.7518 22.4967 22.1709 22.9642 21.4626C23.0492 21.3351 23.0492 21.1651 22.9642 21.0234C22.4967 20.3151 21.8451 19.7343 21.0801 19.3518C20.5559 19.0826 20.3434 18.4451 20.5984 17.9209C20.8676 17.3968 21.5051 17.1843 22.0292 17.4393C23.1201 17.9918 24.0551 18.8134 24.7351 19.8334C25.3017 20.6834 25.3017 21.7743 24.7351 22.6243C24.0551 23.6443 23.1201 24.4659 22.0292 25.0043C21.8592 25.1034 21.7034 25.1459 21.5476 25.1459Z"
          fill="url(#paint2_linear_1475_4605)"
        />
        <path
          d="M31.1668 11.0643V11.3335L2.8335 11.3477V11.0643C2.8335 5.90766 5.90766 2.8335 11.0643 2.8335H22.936C28.0927 2.8335 31.1668 5.90766 31.1668 11.0643Z"
          fill="url(#paint3_linear_1475_4605)"
        />
        <defs>
           <linearGradient
            id="paint0_linear_1475_4605"
            x1="10.0408"
            y1="14.4592"
            x2="37.514"
            y2="37.1117"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#F7971E" />
            <stop offset="1" stopColor="#FFD200" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1475_4605"
            x1="10.3419"
            y1="18.5816"
            x2="17.145"
            y2="20.9389"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#F7971E" />
            <stop offset="1" stopColor="#FFD200" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1475_4605"
            x1="21.6719"
            y1="18.5631"
            x2="28.4792"
            y2="20.9152"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#F7971E" />
            <stop offset="1" stopColor="#FFD200" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1475_4605"
            x1="10.0408"
            y1="4.17532"
            x2="19.8829"
            y2="23.0792"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#F7971E" />
            <stop offset="1" stopColor="#FFD200" />
          </linearGradient>
        </defs>
      </svg>
    ),
    img: img1,
    title: " System Design",
    description: `As a software engineer, I am very familiar with the process of designing software systems. In my opinion, the key to successful software design is to strike a balance between functionality and feasibility. That is, the system must be capable of performing the desired tasks, but it also must be realistic in terms of time and resources. To achieve this balance, I rely on my experience and intuition, as well as on input from other team members. In addition, I am always open to feedback and willing to make changes if necessary. As a result, I believe that I have the skills and knowledge necessary to design effective software systems.`,
    content:
      "As a software engineer, I am very familiar with the process of designing software systems",
  },

  {
    id: 2,
    icon: (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M22.936 2.8335H11.0785C5.92182 2.8335 2.84766 5.90766 2.84766 11.0643V22.9218C2.84766 28.0785 5.92182 31.1526 11.0785 31.1526H22.936C28.0926 31.1526 31.1668 28.0785 31.1668 22.9218V11.0643C31.1668 5.90766 28.0926 2.8335 22.936 2.8335Z"
          fill="url(#paint0_linear_1532_4482)"
        />
        <path
          d="M12.1123 20.6125V14.535C13.3023 14.0958 14.1665 12.9625 14.1665 11.6167C14.1665 9.90252 12.764 8.5 11.0498 8.5C9.33562 8.5 7.93311 9.90252 7.93311 11.6167C7.93311 12.9625 8.79729 14.0958 9.98729 14.535V20.6125C8.91062 21.0375 8.14564 22.0859 8.14564 23.3042C8.14564 24.905 9.44896 26.2083 11.0498 26.2083C12.6506 26.2083 13.9539 24.905 13.9539 23.3042C13.9539 22.0859 13.189 21.0375 12.1123 20.6125Z"
          fill="#F7971E"
        />
        <path
          d="M24.7913 20.6123V12.3956C24.7913 11.0356 23.6722 9.91646 22.3122 9.91646H20.7113L21.0088 9.67561C21.4622 9.29311 21.5188 8.62728 21.1505 8.17395C20.768 7.72061 20.1022 7.66398 19.6488 8.03232L17.0988 10.1573C16.858 10.3556 16.7163 10.6531 16.7163 10.979C16.7163 11.3048 16.858 11.5881 17.0988 11.8006L19.6488 13.9256C19.8472 14.0956 20.088 14.1665 20.3288 14.1665C20.6263 14.1665 20.938 14.039 21.1505 13.784C21.533 13.3306 21.4622 12.6648 21.0088 12.2823L20.7113 12.0415H22.3122C22.5105 12.0415 22.6663 12.1973 22.6663 12.3956V20.6123C21.5897 21.0373 20.8247 22.0856 20.8247 23.304C20.8247 24.9048 22.128 26.2081 23.7288 26.2081C25.3297 26.2081 26.633 24.9048 26.633 23.304C26.633 22.0856 25.868 21.0373 24.7913 20.6123Z"
          fill="#F7971E"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1532_4482"
            x1="10.0513"
            y1="7.29655"
            x2="44.6525"
            y2="27.2674"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#F7971E" />
            <stop offset="1" stopColor="#FFD200" />
          </linearGradient>
        </defs>
      </svg>
    ),
    img: img2,
    title: "Programming",
    description: `I am a skilled software developer with experience in programming languages such as Java and Python. I am also familiar with libraries and frameworks such as React, Angular, and Spring Boot. I am adept at using tools such as IntelliJ IDEA and Eclipse for development purposes. In addition, I am also knowledgeable about database technologies such as MySQL and MongoDB. I have developed various applications using these technologies, and I am confident in my ability to create robust and scalable software solutions. 
     Consequently, I believe that I have the skills and knowledge required to be a successful software developer.`,
    content:
      "I am a skilled software developer with experience in programming languages such as Java and Python",
  },

  {
    id: 3,
    img: img3,
    icon: (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M29.75 9.91683V24.0835C29.75 28.3335 27.625 31.1668 22.6667 31.1668H12.75C14.4642 29.8777 15.5833 27.8093 15.5833 25.5002C15.5833 21.5902 12.41 18.4168 8.5 18.4168C6.89917 18.4168 5.42583 18.941 4.25 19.8335V9.91683C4.25 5.66683 6.375 2.8335 11.3333 2.8335H22.6667C27.625 2.8335 29.75 5.66683 29.75 9.91683Z"
          fill="url(#paint0_linear_1475_3206)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5415 5.3125C21.1283 5.3125 21.604 5.7882 21.604 6.375V9.20833C21.604 10.1799 22.4033 10.9792 23.3748 10.9792H26.2082C26.795 10.9792 27.2707 11.4549 27.2707 12.0417C27.2707 12.6285 26.795 13.1042 26.2082 13.1042H23.3748C21.2297 13.1042 19.479 11.3535 19.479 9.20833V6.375C19.479 5.7882 19.9547 5.3125 20.5415 5.3125Z"
          fill="url(#paint1_linear_1475_3206)"
        />
        <path
          d="M8.49984 18.4165C4.58984 18.4165 1.4165 21.5898 1.4165 25.4998C1.4165 29.4098 4.58984 32.5832 8.49984 32.5832C12.4098 32.5832 15.5832 29.4098 15.5832 25.4998C15.5832 21.5898 12.4098 18.4165 8.49984 18.4165ZM7.30984 27.299C7.66401 27.6532 7.66401 28.234 7.30984 28.6023C7.12568 28.7865 6.89902 28.8715 6.65819 28.8715C6.41735 28.8715 6.19066 28.7865 6.00649 28.6023L3.55568 26.1515C3.20152 25.7974 3.20152 25.2165 3.55568 24.8481L6.00649 22.3973C6.36066 22.0432 6.94151 22.0432 7.30984 22.3973C7.66401 22.7515 7.66401 23.3323 7.30984 23.7006L5.51069 25.4998L7.30984 27.299ZM13.4299 26.1515L10.979 28.6023C10.7948 28.7865 10.5682 28.8715 10.3273 28.8715C10.0865 28.8715 9.85982 28.7865 9.67565 28.6023C9.32148 28.2482 9.32148 27.6674 9.67565 27.299L11.4748 25.4998L9.67565 23.7006C9.32148 23.3465 9.32148 22.7657 9.67565 22.3973C10.0298 22.0432 10.6107 22.0432 10.979 22.3973L13.4299 24.8481C13.784 25.2165 13.784 25.7832 13.4299 26.1515Z"
          fill="url(#paint2_linear_1475_3206)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1475_3206"
            x1="10.7366"
            y1="7.29879"
            x2="43.4462"
            y2="24.29"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#F7971E" />
            <stop offset="1" stopColor="#FFD200" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1475_3206"
            x1="21.461"
            y1="6.54046"
            x2="30.9811"
            y2="12.0352"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#F7971E" />
            <stop offset="1" stopColor="#FFD200" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1475_3206"
            x1="5.02015"
            y1="20.6492"
            x2="22.3294"
            y2="30.6396"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#F7971E" />
            <stop offset="1" stopColor="#FFD200" />
          </linearGradient>
        </defs>
      </svg>
    ),
    title: "Unit testing",
    description: `I am confident in my skills when it comes to software unit testing. I am well-versed in multiple programming languages, and I have experience with a variety of testing libraries. I am able to quickly identify errors and faults in code, and I have a keen eye for detail. I am also familiar with the concept of "test-driven development" and how to effectively implement it. Unit testing is an essential part of the software development process, and I am confident that I have the skills and knowledge necessary to perform it effectively.`,
    content: " I am confident in my skills when it comes to software unit testing",
  },

  {
    id: 4,
    img: img4,
    icon: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M20.936 0.833496H9.07849C3.92182 0.833496 0.847656 3.90766 0.847656 9.06433V20.9218C0.847656 26.0785 3.92182 29.1526 9.07849 29.1526H20.936C26.0926 29.1526 29.1668 26.0785 29.1668 20.9218V9.06433C29.1668 3.90766 26.0926 0.833496 20.936 0.833496Z"
          fill="#F7971E"
        />
        <path
          d="M14.9315 9.78988C14.4693 9.79707 14.01 9.86219 13.5638 9.98336V10.6114C13.5663 10.8753 13.4145 11.1162 13.1753 11.2277C12.952 11.3283 12.7378 11.4478 12.5346 11.5846C12.3087 11.7292 12.0211 11.736 11.789 11.6022L11.2261 11.2879C10.5346 11.9424 10.0377 12.7754 9.79004 13.6948L10.7657 14.2397C10.6657 14.7415 10.6657 15.2583 10.7657 15.7604L9.7902 16.3051C10.0366 17.2247 10.5327 18.0583 11.2233 18.7136L11.789 18.3977C12.0214 18.2639 12.309 18.2706 12.5348 18.4153C12.7378 18.5521 12.952 18.6716 13.1753 18.7722C13.4145 18.8837 13.5663 19.1245 13.564 19.3884V20.019C14.5046 20.2751 15.4967 20.2742 16.4369 20.0165V19.3884C16.4346 19.1245 16.5864 18.8836 16.8256 18.7722C17.0489 18.6715 17.2631 18.552 17.4663 18.415C17.6922 18.2706 17.9795 18.2638 18.2119 18.3977L18.7748 18.7121V18.7119C19.4663 18.0574 19.9632 17.2244 20.2108 16.305L19.2352 15.7601V15.7603C19.3352 15.2583 19.3352 14.7415 19.2352 14.2397L20.2107 13.695V13.6947C19.9641 12.7751 19.4682 11.9417 18.7776 11.2862L18.2119 11.6021C17.9797 11.736 17.6921 11.7292 17.4663 11.5846C17.2631 11.4477 17.0489 11.3283 16.8256 11.2276C16.5864 11.1162 16.4346 10.8753 16.4369 10.6114V9.98083C15.9463 9.84845 15.4396 9.78438 14.9315 9.78988L14.9315 9.78988ZM17.3688 14.9999C17.3688 15.628 17.1193 16.2304 16.6753 16.6747C16.231 17.1188 15.6285 17.3683 15.0004 17.3683C14.3724 17.3683 13.77 17.1188 13.3256 16.6747C12.8816 16.2305 12.632 15.628 12.632 14.9999C12.632 14.3718 12.8816 13.7694 13.3256 13.3251C13.7699 12.881 14.3724 12.6315 15.0004 12.6315C15.6285 12.6315 16.2309 12.881 16.6753 13.3251C17.1193 13.7694 17.3688 14.3718 17.3688 14.9999Z"
          fill="#F7971E"
        />
        <path
          d="M21.6317 22.1052C21.6317 23.1515 20.7833 23.9999 19.737 23.9999C18.6907 23.9999 17.8423 23.1515 17.8423 22.1052C17.8423 21.0588 18.6907 20.2104 19.737 20.2104C20.7833 20.2104 21.6317 21.0588 21.6317 22.1052Z"
          fill="#F7971E"
        />
        <path
          d="M12.1576 7.89472C12.1576 8.94103 11.3092 9.78943 10.2629 9.78943C9.21656 9.78943 8.36816 8.94103 8.36816 7.89472C8.36816 6.8484 9.21656 6 10.2629 6C11.3092 6 12.1576 6.8484 12.1576 7.89472Z"
          fill="#F7971E"
        />
        <path
          d="M6.00052 15.0001C6.002 16.8666 6.58333 18.6869 7.6643 20.2087C8.74531 21.7305 10.2723 22.879 12.0344 23.4952C13.7966 24.1112 15.7065 24.1647 17.4999 23.6481L17.2372 22.7378C14.8054 23.4382 12.1856 22.9556 10.1629 21.4346C8.14027 19.9137 6.94952 17.5309 6.94741 15.0001C6.94128 13.2185 7.5319 11.486 8.62515 10.0791L7.87571 9.49951C6.65368 11.0722 5.99328 13.0085 6.00005 15.0001L6.00052 15.0001Z"
          fill="#F7971E"
        />
        <path
          d="M15.0012 6C14.1556 5.99958 13.3141 6.118 12.5015 6.35188L12.7641 7.26221C14.9735 6.6238 17.3506 6.96045 19.296 8.18715C21.2413 9.41385 22.5693 11.4137 22.9454 13.6824C23.3216 15.9512 22.7099 18.2724 21.2647 20.0616L22.0011 20.6573C23.4324 18.8861 24.1394 16.6382 23.9789 14.3669C23.8186 12.0951 22.8031 9.96884 21.1372 8.41636C19.4713 6.86378 17.2787 6.00038 15.0016 6.00038L15.0012 6Z"
          fill="#F7971E"
        />
      </svg>
    ),
    title: "System Design",
    description: `Planning is the first and most important step in any software system design or architecting process. By understanding the requirements of the system and the environment in which it will operate, we can create a design that is both effective and efficient. Once the planning stage is complete, we can move on to designing the actual software architecture. This involves deciding how the various components of the system will interact with each other and with the outside world. We also need to take into account things like performance, security, and scalability when designing the architecture. By carefully considering all of these factors, we can create a software system that is reliable and easy to maintain.`,
    content:
      "Planning is the first and most important step in any software system design or architecting process",
  },

  {
    id: 5,
    img: img5,
    icon: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M20.936 0.833496H9.07849C3.92182 0.833496 0.847656 3.90766 0.847656 9.06433V20.9218C0.847656 26.0785 3.92182 29.1526 9.07849 29.1526H20.936C26.0926 29.1526 29.1668 26.0785 29.1668 20.9218V9.06433C29.1668 3.90766 26.0926 0.833496 20.936 0.833496Z"
          fill="#F7971E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.4094 12.4818C23.032 11.8971 22.5041 11.4258 21.8817 11.1177C21.9354 10.8337 21.9635 10.5455 21.9661 10.2565C21.968 9.088 21.4906 7.97018 20.6459 7.16604C19.8012 6.36204 18.6639 5.94249 17.5015 6.00636C16.3392 6.07024 15.2542 6.61167 14.5019 7.50348C13.7486 6.61167 12.6629 6.07024 11.4999 6.00636C10.3367 5.94249 9.19877 6.36162 8.35285 7.16548C7.50707 7.96948 7.02789 9.08717 7.02789 10.2565C7.03046 10.5454 7.05862 10.8337 7.11224 11.1177C6.08636 11.6194 5.34307 12.5619 5.09206 13.6794C4.84105 14.7968 5.10953 15.9683 5.8217 16.8634C6.53386 17.7585 7.61266 18.2802 8.75389 18.2816H20.2403C21.143 18.2823 22.0157 17.9566 22.6988 17.3642C23.3818 16.772 23.8296 15.9525 23.9601 15.0559C24.0906 14.1593 23.8951 13.2456 23.4094 12.4819L23.4094 12.4818Z"
          fill="#F7971E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1342 19.4032C17.1346 19.5309 17.1257 19.6587 17.1073 19.7852C17.0441 20.2203 16.8736 20.6328 16.6115 20.9853C16.4592 21.1893 16.2786 21.3705 16.0753 21.5234C15.7242 21.7866 15.3133 21.9577 14.8797 22.0212C14.6272 22.0571 14.3709 22.0571 14.1184 22.0212C13.6848 21.9578 13.2739 21.7867 12.9229 21.5234C12.7196 21.3705 12.539 21.1893 12.3867 20.9853C12.1246 20.6328 11.9541 20.2203 11.8907 19.7852C11.8725 19.6586 11.8635 19.5309 11.8639 19.4032V19.3628H8.92336V19.4032C8.92255 19.6733 8.94232 19.9431 8.98226 20.2103H10.0116L10.0571 20.4201L10.0573 20.4203C10.1715 20.9212 10.3701 21.3989 10.6443 21.8329L10.7596 22.0158L10.0303 22.7476L10.0305 22.7478C10.3523 23.1812 10.7352 23.5655 11.1671 23.8887L11.8962 23.1569L12.0784 23.2725C12.511 23.5473 12.9869 23.7465 13.4858 23.8618L13.6948 23.9076V24.9409V24.9408C14.2281 25.0197 14.77 25.0197 15.3033 24.9408V23.9075L15.5123 23.8617V23.8618C16.0111 23.746 16.4869 23.5468 16.9197 23.2725L17.1019 23.1569L17.8312 23.8887C18.2629 23.5655 18.6458 23.1812 18.9676 22.7478L18.2385 22.0159L18.3538 21.833V21.8329C18.6267 21.3985 18.8242 20.9208 18.9381 20.4203L18.9863 20.2104L20.0158 20.2103C20.0558 19.9431 20.0755 19.6733 20.0747 19.4032V19.3628H17.1341L17.1342 19.4032Z"
          fill="#F7971E"
        />
      </svg>
    ),
    title: "Front End",
    description: `As a front end programmer, I am always keen on keeping my skills up to date and improving my abilities. Web development is an ever-changing field and new frameworks and libraries are constantly being released. As such, it is important for me to be able to adapt to new technologies quickly. I have experience with both React and Angular, two of the most popular front-end frameworks. I am confident in my ability to learn new technologies quickly and build high-quality web applications. In addition to my technical skills, I also have a strong eye for design and user experience. I believe that these skills make me an excellent front-end developer who can build applications that are both beautiful and user-friendly.
`,
    content:
      "As a front end programmer, I am always keen on keeping my skills up to date and improving my abilities",
  },

  {
    id: 6,
    img: img6,
    icon: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M20.9357 0.833496H9.07824C3.92158 0.833496 0.847412 3.90766 0.847412 9.06433V20.9218C0.847412 26.0785 3.92158 29.1526 9.07824 29.1526H20.9357C26.0924 29.1526 29.1666 26.0785 29.1666 20.9218V9.06433C29.1666 3.90766 26.0924 0.833496 20.9357 0.833496Z"
          fill="#F7971E"
        />
        <path
          d="M21.3942 17.9163C21.3942 18.134 21.2161 18.3121 20.9984 18.3121H20.8756C20.7529 17.9282 20.4363 17.6273 20.0166 17.5243L19.9573 17.5125L19.985 17.469C20.27 16.9979 20.1987 16.3962 19.8069 16.0043L18.849 15.0543C18.6273 14.8286 18.3265 14.702 18.0098 14.702C17.7921 14.702 17.5782 14.7654 17.3963 14.8762L17.321 14.9237L17.3051 14.8565C17.1745 14.3259 16.7035 13.9539 16.1532 13.9539H14.7955C14.2452 13.9539 13.7703 14.3259 13.6357 14.8682L13.6198 14.9355L13.5803 14.9117C13.3904 14.789 13.1687 14.7257 12.9429 14.7257C12.6302 14.7257 12.3294 14.8485 12.1078 15.0702L11.1458 16.0281C10.75 16.4199 10.6827 17.0256 10.9756 17.4926L11.0073 17.5441L10.9677 17.556C10.56 17.6471 10.2434 17.9361 10.1087 18.312L9.97808 18.3121C9.76036 18.3121 9.58228 18.134 9.58228 17.9163C9.58228 17.6749 9.60216 17.4215 9.64178 17.1722C10.0019 14.7527 12.0938 12.9883 14.5106 12.9883H16.4662C18.9379 12.9883 20.9905 14.8213 21.3351 17.1722C21.3744 17.4215 21.3942 17.6749 21.3942 17.9163L21.3942 17.9163Z"
          fill="#F7971E"
        />
        <path
          d="M18.7819 8.3449C18.7819 10.2489 17.3133 12.5486 15.4885 12.5486C13.6636 12.5486 12.1951 10.2488 12.1951 8.3449C12.1951 6.50031 13.6716 5 15.4886 5C17.3055 5 18.7819 6.50028 18.7819 8.3449Z"
          fill="#F7971E"
        />
        <path
          d="M12.0169 10.9059C12.0169 12.1568 11.0392 13.6689 9.81997 13.6689C8.60463 13.6689 7.62305 12.1568 7.62305 10.9059C7.62305 9.67487 8.60866 8.67725 9.81997 8.67725C11.0313 8.67725 12.0169 9.67487 12.0169 10.9059Z"
          fill="#F7971E"
        />
        <path
          d="M23.3767 10.9059C23.3767 12.1568 22.3989 13.6689 21.1797 13.6689C19.9645 13.6689 18.9868 12.1568 18.9868 10.9059C18.9868 9.67487 19.9724 8.67725 21.1797 8.67725C22.3911 8.67725 23.3767 9.67487 23.3767 10.9059Z"
          fill="#F7971E"
        />
        <path
          d="M10.3226 14.0215C9.53483 14.8726 9.03215 15.9176 8.86194 17.0458C8.83018 17.2398 8.81044 17.4337 8.79857 17.6277H6.39581C6.17823 17.6277 6 17.4496 6 17.2319C6 16.3769 6.33645 15.5694 6.9421 14.9597C7.54778 14.3541 8.35526 14.0215 9.21812 14.0215H10.3226Z"
          fill="#F7971E"
        />
        <path
          d="M24.9999 17.2314C24.9999 17.4491 24.8258 17.6272 24.6041 17.6272H22.1776C22.1657 17.4332 22.1458 17.2433 22.1182 17.0572C21.9518 15.925 21.4334 14.8603 20.6497 14.021H21.7856C23.5591 14.021 24.9999 15.4619 24.9999 17.2314H24.9999Z"
          fill="#F7971E"
        />
        <path
          d="M19.8379 18.2962L19.2402 18.1577C19.1729 17.9519 19.0897 17.7579 18.9907 17.5639L19.3074 17.0534C19.4024 16.8989 19.3786 16.6971 19.248 16.5665L18.29 15.6125C18.1594 15.4819 17.9616 15.4581 17.8071 15.5531L17.2806 15.8737C17.0867 15.7747 16.8848 15.6916 16.675 15.6282L16.5367 15.0464C16.4932 14.8683 16.3347 14.7456 16.1526 14.7456H14.795C14.6089 14.7456 14.4505 14.8723 14.407 15.0504L14.2685 15.6362C14.0587 15.7034 13.8569 15.7906 13.6629 15.8896L13.1522 15.5768C12.9978 15.4779 12.796 15.5016 12.6653 15.6322L11.7034 16.5862C11.5728 16.7168 11.5489 16.9186 11.6479 17.077L11.9686 17.5956C11.8736 17.7855 11.7905 17.9835 11.7272 18.1854L11.1374 18.328C10.9593 18.3676 10.8325 18.5259 10.8325 18.7119V20.0617C10.8325 20.2438 10.9551 20.4021 11.1374 20.4456L11.7351 20.5882C11.8024 20.7941 11.8855 20.9921 11.9845 21.182L11.6678 21.6887C11.5728 21.8431 11.5965 22.0489 11.7273 22.1795L12.6851 23.1335C12.7604 23.2087 12.8632 23.2484 12.9622 23.2484C13.0335 23.2484 13.1048 23.2285 13.1721 23.189L13.6946 22.8683C13.8846 22.9673 14.0864 23.0504 14.3003 23.1138L14.4388 23.6957C14.4823 23.8736 14.6406 24.0004 14.8226 24.0004H16.1804C16.3663 24.0004 16.5247 23.8738 16.5683 23.6955L16.7068 23.1057C16.9167 23.0384 17.1185 22.9553 17.3125 22.8524L17.8231 23.1651C17.8865 23.2047 17.9618 23.2245 18.033 23.2245C18.132 23.2245 18.2348 23.185 18.3101 23.1097L19.2721 22.1598C19.4027 22.0292 19.4264 21.8273 19.3274 21.669L19.0067 21.1504C19.1057 20.9604 19.1848 20.7624 19.2522 20.5566L19.838 20.4141C20.0161 20.3745 20.1428 20.2123 20.1428 20.0302V18.6804C20.1428 18.4982 20.0161 18.3398 19.838 18.2962L19.8379 18.2962ZM15.4876 21.1068C14.5296 21.1068 13.7498 20.3269 13.7498 19.3729C13.7498 18.415 14.5296 17.6391 15.4876 17.6391C16.4454 17.6391 17.2253 18.415 17.2253 19.3729C17.2252 20.3269 16.4454 21.1068 15.4876 21.1068Z"
          fill="#F7971E"
        />
      </svg>
    ),
    title: "Back End",
    description: `I am confident in my ability to produce high-quality code using Java Spring Boot, Node JS, and Python. I have a strong understanding of object-oriented programming principles and have experience working with databases such as MySQL and MongoDB. In addition, I am familiar with various frameworks and libraries such as Django and Flask. I am also comfortable working with RESTful APIs. I am confident in my ability to troubleshoot and debug software issues. In addition, I have excellent problem-solving skills. Overall, I am a highly skilled and experienced back-end developer.`,
    content:"I am confident in my ability to produce high-quality code using Java Spring Boot, Node JS, and Python.",
  },
];
