import { useContext } from "react";
import { themeContext } from "../../context/themeContext";
import { bannerTwoData } from "../../data/banner";
import withVideoModal from "../../HOC/withVideoModal";

const HeroBanner3 = ({ setOpen }) => {
  const { theme } = useContext(themeContext);

  return (
    <section className="hero-banner digital-marketer" id="home">
      <div className="container">
        {bannerTwoData.map((data, index) => {
          return (
            <div
              key={index}
              className="row justify-content-between align-items-center flex-column-reverse flex-lg-row"
            >
              <div className="col-12 col-lg-7 hero-content">
                <div data-aos="fade-right">
                  <div className="hero-header d-flex align-items-center gap-2">
                    <div className="hand-icon">
                      {/*-- Icons Hands -*/}
                      <img
                        className="d-inline-block"
                        src={data.handIcon}
                        alt="icon"
                      />
                    </div>
                    <h3 className="mb-0 pt-3">{data.subTitle}</h3>
                  </div>
                  <div className="hero-title">
                    <h1 className="mb-0">
                      {data.title}
                      {/*-- Icons Flower -*/}
                      <span className="fan-icons d-none d-sm-inline-block">
                        <img src={data.fanIcon} alt="icon" />
                      </span>
                    </h1>
                  </div>
                  {/*-- Banner Content -*/}
                  <div className="hero-pera my-2 ">
                    <p>{data.content}</p>
                  </div>
                  <div className="hero-button d-flex gap-3">
                    <a
                      href={data.cv}
                      download={data.cv}
                      target="_blank"
                      className="download-btn"
                      rel="noreferrer"
                    >
                      Download CV
                    </a>
                    <a
                      href="#contact"
                      className="lets-talk-btn d-flex align-items-center"
                    >
                      Let's Talk
                      <svg
                        width="24"
                        height="9"
                        viewBox="0 0 24 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.25001 3.99973C0.97387 3.99972 0.750007 4.22357 0.75 4.49972C0.749993 4.77586 0.973846 4.99972 1.24999 4.99973L1.25001 3.99973ZM23.1035 4.85381C23.2988 4.65855 23.2988 4.34197 23.1036 4.1467L19.9217 0.964642C19.7264 0.769375 19.4098 0.769367 19.2146 0.964625C19.0193 1.15988 19.0193 1.47646 19.2145 1.67173L22.0429 4.50023L19.2144 7.32859C19.0191 7.52384 19.0191 7.84043 19.2144 8.03569C19.4096 8.23096 19.7262 8.23097 19.9215 8.03571L23.1035 4.85381ZM1.24999 4.99973L22.75 5.00024L22.75 4.00024L1.25001 3.99973L1.24999 4.99973Z"
                          fill="black"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-lg-5 hero-profile ps-0"
                data-aos="fade-left"
              >
                {/*-- Banner user image -*/}
                <div className="hero-profile-img w-100 text-center position-relative">
                  <img
                    src={data.profileImage[`${theme}`]}
                    className="w-100"
                    alt="Men in Black suit"
                  />
                  <div className="videoBtn header-3">
                    <button
                      onClick={() => setOpen(true)}
                      type="button"
                      className="videoPlayButton"
                      data-bs-toggle="modal"
                      data-bs-target="#videoModal"
                    >
                      <svg
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M29.8614 59.7228C46.3534 59.7228 59.7228 46.3534 59.7228 29.8614C59.7228 13.3694 46.3534 0 29.8614 0C13.3694 0 0 13.3694 0 29.8614C0 46.3534 13.3694 59.7228 29.8614 59.7228Z"
                          fill="white"
                        />
                        <path
                          d="M39.9428 25.8058C42.6614 27.335 42.6614 31.2492 39.9428 32.7784L27.835 39.589C25.1686 41.0889 21.874 39.162 21.874 36.1027V22.4815C21.874 19.4222 25.1686 17.4953 27.835 18.9952L39.9428 25.8058Z"
                          fill="url(#paint0_linear_1885_9145)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1885_9145"
                            x1="42.3162"
                            y1="22.5865"
                            x2="22.0057"
                            y2="53.8663"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#F7971E" />
                            <stop offset="1" stopColor="#FFD200" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default withVideoModal(HeroBanner3);
