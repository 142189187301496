import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import {Route, Routes, useLocation} from "react-router-dom";
import HeroBanner2 from "./components/banner/hero-banner2";
import HeroBanner3 from "./components/banner/hero-banner3";
import HeroBanner from "./components/banner/hero-banner";
import CursorAnimation from "./components/cursor-animation";
import Layout from "./layout/layout";
import "./scss/app.scss";
import {useEffect} from "react";

const App = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const darkMode = searchParams.get('dark-mode');

    useEffect(() => {
        localStorage.setItem("theme", darkMode === 'true' ? 'dark' : 'light');
    }, [darkMode])

    return (
        <>
            <CursorAnimation/>
            <Routes>
                <Route exact path="/">
                    <Route
                        index
                        element={
                            <Layout>
                                <HeroBanner/>
                            </Layout>
                        }
                    />
                </Route>
                <Route path="/header2">
                    <Route
                        index
                        element={
                            <Layout>
                                <HeroBanner2/>
                            </Layout>
                        }
                    />
                </Route>
                <Route path="/header3">
                    <Route
                        index
                        element={
                            <Layout>
                                <HeroBanner3/>
                            </Layout>
                        }
                    />
                </Route>
            </Routes>
        </>
    );
};
export default App;
