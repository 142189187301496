import { bannerThreeData } from "../../data/banner";
import { social } from "../../data/social-media";

const HeroBanner = () => {
  return (
    <section className="hero-banner designer" id="home">
      <div className="container">
        {bannerThreeData.map((data, index) => {
          return (
            <div
              key={index}
              className=" row justify-content-between align-items-center flex-column-reverse flex-lg-row"
            >
              <div className="col-12 col-lg-7 hero-content">
                <div data-aos="fade-right">
                  <div className="hero-header d-flex align-items-center gap-2">
                    <div className="hand-icon">
                      {/*-- Icons Hands -*/}
                      <img src={data.handIcon} alt="icon" />
                    </div>
                    <h3 className="mb-0">{data.subTitle}</h3>
                  </div>
                  <div className="hero-title">
                    <h1 className="mb-0">
                      {data.title}
                      {/*-- Icons Flower -*/}
                      <span className="fan-icons d-none d-sm-inline-block">
                        <img src={data.fanIcon} alt="icon" />
                      </span>
                    </h1>
                  </div>
                  {/*-- Banner Content -*/}
                  <div className="hero-pera my-2 ">
                    <p>{data.content}</p>
                  </div>

                  {/*-- Banner socials links -*/}
                  <ul className="social-media d-flex position-static">
                    {social.map(({ icon, href }, index) => {
                      return (
                        <li key={index}>
                          <a href={href}>
                            <i className={icon} />
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="hero-button d-flex gap-3">
                    <a
                      href={data.cv}
                      download={data.cv}
                      target="_blank"
                      className="download-btn"
                      rel="noreferrer"
                    >
                      Download CV
                    </a>
                    <a
                      href="#contact"
                      className="lets-talk-btn d-flex align-items-center"
                    >
                      Let's Talk
                      <svg
                        width="24"
                        height="9"
                        viewBox="0 0 24 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.25001 3.99973C0.97387 3.99972 0.750007 4.22357 0.75 4.49972C0.749993 4.77586 0.973846 4.99972 1.24999 4.99973L1.25001 3.99973ZM23.1035 4.85381C23.2988 4.65855 23.2988 4.34197 23.1036 4.1467L19.9217 0.964642C19.7264 0.769375 19.4098 0.769367 19.2146 0.964625C19.0193 1.15988 19.0193 1.47646 19.2145 1.67173L22.0429 4.50023L19.2144 7.32859C19.0191 7.52384 19.0191 7.84043 19.2144 8.03569C19.4096 8.23096 19.7262 8.23097 19.9215 8.03571L23.1035 4.85381ZM1.24999 4.99973L22.75 5.00024L22.75 4.00024L1.25001 3.99973L1.24999 4.99973Z"
                          fill="black"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 hero-profile pe-0">
                <div className="hero-profile-img w-100 text-center">
                  {/*-- Banner user image -*/}
                  <img src={data.profileImage} alt="hero-banner" />
                </div>
                <div className="illustrator"></div>
                <div className="overlay"></div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default HeroBanner;
