import img1 from '../img/clients/client-logo-1.svg'
import img2 from '../img/clients/client-logo-2.svg'
import img3 from '../img/clients/client-logo-3.svg'
import img4 from '../img/clients/client-logo-4.svg'
import img5 from '../img/clients/client-logo-5.svg'
import img6 from '../img/clients/client-logo-6.svg'
import img7 from '../img/clients/client-logo-7.svg'
import img8 from '../img/clients/client-logo-8.svg'
import img9 from '../img/clients/client-logo-9.svg'
import img10 from '../img/clients/client-logo-10.svg'
import img11 from '../img/clients/client-logo-1.svg'
import img12 from '../img/clients/client-logo-2.svg'


export const validClients = [
  { src: img1, link: "" },

  { src: img2, link: "" },

  { src: img3, link: "" },

  { src: img4, link: "" },

  { src: img5, link: "" },

  { src: img6, link: "" },

  { src: img7, link: "" },

  { src: img8, link: "" },

  { src: img9, link: "" },

  { src: img10, link: "" },

  { src: img11, link: "" },

  { src: img12, link: "" },
];
