import { useState, useEffect} from "react";

//counter
const Counter = ({min, max}) => {
    const [ count, setCount ] = useState(0);

    useEffect(()=> {
        const counter = (minimum, maximum) => {
            for (let count = minimum; count <= maximum; count++) {
                setTimeout(() => {
                    setCount(count);
                }, 1000);
            }
        }
        counter(min, max)
    },[min, max])

    return (
        <div>
            {count}
        </div>
    );
}

export default Counter