import { motion } from "framer-motion";
import { useRef, useState } from "react";
import email from '@emailjs/browser';
import contactDetails from "../data/contact.json";
import rightArrow from "../img/icons/arrow-right.svg";
import envelop from "../img/icons/envelop.svg";
import location from "../img/icons/map-marker.svg";
import phone from "../img/icons/phone.svg";
import whiteArrow from "../img/icons/contact/arrow-white.svg";
import layer from "../img/icons/contact/layer.svg";
import message from "../img/icons/contact/message.svg";
import profile from "../img/icons/contact/profile.svg";
import contact from "../img/icons/contact/sms.svg";
import ContactModal from "../components/contact-modal"

const Contact = () => {
  const [ openModal, setOpenModal ] = useState(false)

  const inputRef = useRef();
  const form = useRef();
  const clickHandler = () => {
    inputRef.current.focus();
  };
  const handleClose =()=> setOpenModal(false)

  const sendEmail = (e) => {
    e.preventDefault();

    email.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
    ).then((result) => {
      setOpenModal(true)
    }, (error) => {
      // console.log(error.text);
    });
    e.target.reset()
  }

  return (
      <>
       <section id="contact" className="contact-section">
      <div className="container">
        <div className="d-flex">
          {/* Contact heading */}
          <h2 className="section-heading">
            <span className="title-signature">Contact Us</span>
            <sub>•</sub>
          </h2>
        </div>
        <div className="row">
          <div className="col-12 col-lg-3 ">
            {/* Contact info cards */}
            <div className="contact-card">
              {/* Single info card */}
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 100,
                  damping: 20,
                }}
                viewport={{ amount: 0.5, once: false }}
                className="contact-card-item"
              >
                <div className="item-card">
                  <div className="contact-icon">
                    <img src={envelop} alt="icon" />
                  </div>
                  <a href="mailto:contact@yahoo.com">{contactDetails.email}</a>
                </div>
              </motion.div>
              {/* Single info card */}
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ amount: 0.5, once: false }}
                transition={{
                  type: "spring",
                  stiffness: 100,
                  damping: 20,
                }}
                className="contact-card-item"
              >
                <div className="item-card">
                  <div className="contact-icon">
                    <img src={phone} alt="icon" />
                  </div>
                  <a href="tel:+8800089899989">{contactDetails.phone}</a>
                </div>
              </motion.div>
              {/* Single info card */}
              <motion.div
                className="contact-card-item w-100 w-md-auto"
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 100,
                  damping: 20,
                }}
                viewport={{ amount: 0.5, once: false }}
              >
                <div className="item-card">
                  <div className="contact-icon">
                    <img src={location} alt="icon" />
                  </div>
                  <p className="mb-0">{contactDetails.location}</p>
                </div>
              </motion.div>
              {/* Contact info button */}
              <div className="d-none d-lg-block">
                <button
                  onClick={clickHandler}
                  className="button-schedule"
                  type="button"
                >
                  Get a Schedule
                  <img src={rightArrow} alt="icon" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 d-block d-lg-none mb-4">
            <button

              className="button-schedule"
              type="button"
            >
              Get a Schedule
              <img src={rightArrow} alt="icon" />
            </button>
          </div>
          <div className="col-12 col-lg-9">
            {/* Contact form */}
            <motion.form
              ref={form}
              onSubmit={sendEmail}
              action=""
              className="contact-form"
              initial={{ x: 200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ amount: 0.3, once: false }}
              transition={{
                x: {
                  type: "spring",
                  stiffness: 100,
                  damping: 20,
                },
              }}
            >
              <div className="row">
                {/* Form field */}
                <div className="col-md-6">
                  <div className="input-icons">
                    <img src={profile} alt="profile" />
                    <input
                      ref={inputRef}
                      id="userName"
                      name="user_name"
                      className="input-field"
                      type="text"
                      placeholder="Name *"
                      required
                    />
                  </div>
                </div>
                {/* Form field */}
                <div className="col-md-6">
                  <div className="input-icons">
                    <img src={contact} alt="icon" />
                    <input
                      id="userEmail"
                      name="user_email"
                      className="input-field"
                      type="email"
                      placeholder="Email *"
                      required
                    />
                  </div>
                </div>
                {/* Form field */}
                <div className="col-md-12">
                  <div className="input-icons">
                    <img src={layer} alt="icon" />
                    <input
                      id="project"
                      name="user_project"
                      className="input-field"
                      type="text"
                      placeholder="Project Title"
                    />
                  </div>
                </div>
                {/* Form field */}
                <div className="col-md-12">
                  <div className="input-icons message">
                    <img src={message} alt="icon" />
                    <textarea
                      id="message"
                      className="input-field"
                      placeholder="Message"
                      name="message"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="send-button">
                <p id="successMessage" className="success-message">
                  <b>{/* User Name replace by js */}</b>, You have successfully
                  submitted from.
                </p>
                <p className="send-button-desc">
                  Whether you’re on the east coast or the west coast.
                </p>
                {/* Form submit button */}
                <button type="submit" className="send-me">
                  Send Me <img src={whiteArrow} alt="icon" />
                </button>
              </div>
            </motion.form>
          </div>
        </div>
      </div>
    </section>
       <ContactModal
           open={openModal}
           close={handleClose}
       />
      </>
  );
};

export default Contact;
