import { useContext } from "react";
import { themeContext } from "../context/themeContext";
import { fotterLogo } from "../data/footerLogo";
import { social } from "../data/social-media";

const Footer = () => {
  const { theme } = useContext(themeContext);

  return (
    <footer id="footer">
      <div className="container">
        <div className="footer-header gap-4 d-flex flex-column justify-content-center align-items-center">
          {/* Footer logo */}
          <img src={fotterLogo[`${theme}`]} alt="qawiun" />
          <h2 className="py-0 mb-0">Thanks for looking</h2>
        </div>

        {/* Footer social links */}
        <div className="social-media d-flex justify-content-center align-items-center">
          {social.map(({ icon, href }, index) => {
            return (
              <a key={index} href={href}>
                <i className={icon} />
              </a>
            );
          })}
        </div>
      </div>
      {/* Footer copyrights */}
      <div className="footer-copyrights">
        <div className="container">
          <p className="text-center">
            Copyright &copy; 2023, Qawiun All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
