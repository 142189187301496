export const social = [
  {
    icon: "fa-brands fa-medium",
    href: "https://medium.com/",
  },
  {
    icon: "fa-brands fa-stack-overflow",
    href: "https://stackoverflow.com/",
  },
  {
    icon: "fab fa-linkedin-in",
    href: "https://www.linkedin.com/",
  },
  {
    icon: "fab fa-github",
    href: "https://github.com/",
  },
];
