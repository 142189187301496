import { motion } from "framer-motion";
import React from "react";
import Star from "../../img/icons/star.svg";
import whiteStar from "../../img/icons/white-star.svg";

const Slide = ({ data }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
      className="slider-item"
    >
      <div className="slider-img text-center">
        <img src={data.image} alt="slider" />
      </div>
      <h4 className="slider-name">
        <span>{data.name}</span>
      </h4>
      <div className="testimonial-stars">
        {Array(5)
          .fill("")
          .map((start, i) => (
            <React.Fragment key={i}>
              {data.rating > i ? (
                <img src={Star} alt="star" />
              ) : (
                <img src={whiteStar} alt="star" />
              )}
            </React.Fragment>
          ))}
      </div>
      <div className="desc-wrapper">
        <p className="slider-desc">{data.description}</p>
      </div>
    </motion.div>
  );
};

export default Slide;
