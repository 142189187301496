import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { useState } from "react";
import { cardsLayout } from "../../data/feature-project/card-list";
import filterList from "../../data/feature-project/filter-list.json";
import FeaturedModal from "./FeaturedModal";

const featureVarient = {
  offscreen: {
    y: 300,
    opacity: 0,
  },

  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      y: { duration: 0.5 },
      type: "tween",
      ease: "easeInOut",
    },
  },
};

const FeatureProject = () => {
  const [selectedFilter, setSeletedFilter] = useState("all");
  const [isSeeAll, setSeeall] = useState(false);
  const [selectedId, setId] = useState(null);
  const filterData =
    selectedFilter === "all"
      ? cardsLayout
      : cardsLayout.filter((card) => {
          return card.filter.some((item) => item === selectedFilter);
        });
  return (
    <motion.section
      id="projects"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.1 }}
      className="project-section "
    >
      <div className="container">
        <div className="section-top">
          <h2 className="section-heading">
            <span className="title-signature black">Featured Projects</span>
          </h2>
        </div>
        {/*Project Filter */}
        <div className="nav nav-pills project-filter-btns feature-tab">
          {/*Filter Buttons */}
          {filterList.map((filter, index) => {
            return (
              <button
                type="button"
                key={index}
                onClick={() => setSeletedFilter(filter.label)}
                className={`${
                  filter.label === selectedFilter
                    ? "active nav-link"
                    : "nav-link"
                }`}
              >
                {filter.label}
              </button>
            );
          })}
        </div>
        <AnimateSharedLayout type="crossfade">
          <motion.div
            variants={featureVarient}
            layout
            className="row grid project-items"
          >
            <AnimatePresence>
              {filterData
                .slice(0, isSeeAll ? filterData.length : 4)
                .map((card) => (
                  <motion.div
                    layoutId={`container-${card.id}`}
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0 }}
                    transition={{ duration: 0.5 }}
                    key={card.id}
                    className={`col-md-6`}
                  >
                    <div
                      className="project-item"
                      onClick={() => setId(card.id)}
                    >
                      <motion.div
                        layoutId={`img-${card.id}`}
                        className="project-item-img"
                      >
                        <img src={card.img} alt="web-design" />
                        <p className="details">See Detail</p>
                      </motion.div>
                      <motion.div
                        className="details"
                        layoutId={`details-${card.id}`}
                      >
                        <h4>{card.title}</h4>
                        <h2>{card.subTitle}</h2>
                        <p>{card.paragraph}</p>
                      </motion.div>
                      <div className="caption"></div>
                    </div>
                  </motion.div>
                ))}
            </AnimatePresence>
          </motion.div>
          <AnimatePresence>
            {selectedId && <FeaturedModal id={selectedId} setId={setId} />}
          </AnimatePresence>
        </AnimateSharedLayout>
        <div className="text-center">
          {/*Project see more button */}
          <button
            onClick={() => setSeeall(true)}
            className={`see-more-btn ${
              filterData.length > 4 && !isSeeAll ? "d-blcok" : "d-none"
            }`}
            type="button"
            id="seeMoreProjects"
          >
            See more work
          </button>
        </div>
      </div>
    </motion.section>
  );
};
export default FeatureProject;
