export const navs = [
  {
    id: 1,
    menu: "Header One",
    link: "/",
  },
  {
    id: 2,
    menu: "Header Two",
    link: "/header2",
  },
  {
    id: 3,
    menu: "Header Three",
    link: "/header3",
  },
];
